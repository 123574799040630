import React from 'react'
import Seo from "../../components/seo"

const PrivacyPolicy = () => {
  return (
    <div className="legal">
      <h1>PrivacyPolicy</h1>
      <p>This privacy statement sets out the privacy policy of Dockovpn (hereinafter «Dockovpn.io», «we») which is located at site https://dockovpn.io (hereinafter «the Website»).
          We are committed to protect and respect your privacy. This privacy policy (together with the Terms of Use) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. The Personal Data Protection Act passed 15.02.2007 (Estonia) and the General Data Protection Regulation ((EU) 2016/679) provides rules for how we can collect, use, and disclose this information. By visiting the Website, you are agreeing to be legally bound by this privacy policy, so please read the following carefully to understand our views and practices regarding your data and how we will treat it. If any term in this Privacy Policy is unacceptable to you, please do not visit, access, or use dockovpn.io.</p>

        <h2>1. What Information Do We Collect?</h2>
        <h3>1.1 Information You Provide</h3>
        <h4>Contact Information</h4>
        <p>We may collect personal and/or business contact information including your first name, last name, mailing address, telephone number, fax number, e-mail address or any other personal data you provide to us for the purposes of doing business with us.</p>
        <h4>Service Account Information</h4>
        <p>We collect information such as your username, authentication tokens and other necessary security information required for authentication and access to our services.</p>
        <h4>Marketing and Communications</h4>
        <p>We may also collect your preferences in receiving updates on our products or marketing material.</p>
        <h3>1.2 Information Collected Automatically</h3>
        <h4>Device & Technical Data</h4>
        <p>We collect technical information when you visit our websites or use our mobile applications or services. This includes information such as your Internet Protocol (IP) address, your login data, type of mobile device you use, your device operating system and browser type, time zone setting and location, language, a unique device identifier, the address of a referring website, the path you take through our websites, and information about your session.</p>
        <h4>Cookies</h4>
        <p>We and our third party service providers use technologies such as cookies to collect information about the use of our website and services.</p>
        <h4>Usage Data</h4>
        <p>During regular usage of our services, we collect information about the date and time of your logins and details of your activities on our platform.</p>
        <h2>2. How We Use Your Personal Information?</h2>
        <p>We use the information we collect in any of the following ways:</p>
        <ul>
        <li><p>Carry out your requests, fulfill orders, and process payments for our products and services;</p></li>
        <li><p>Communicate with you about your orders, purchases or accounts with us, including handling any requests, questions or comments you may have;</p></li>
        <li><p>Provide online services to you, which includes our websites and/or mobile applications;</p></li>
        <li><p>Provide customer support, including processing any concerns about our services;</p></li>
        <li><p>Tell you about our products and services, competitions, offers, promotions or special events that we believe may interest you;</p></li>
        <li><p>Personalize your experience in our online services;</p></li>
        <li><p>Communicate with you about your activities with respect to our Services;</p></li>
        </ul>
        <p>More information about how Google uses data can be found here <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a></p>
    </div>
  )
}

export const Head = () => <Seo title="Privacy Policy" />

export default PrivacyPolicy